<template>
    <div id="empower">
        <div class="mainBtn" @click="saveAreaData()">授权</div>
        <div class="flex">
            <div class="peopleLeft">管理人员</div>
            <div class="peopleRight">采集人员</div>
        </div>
        <div class="flex-between">
            <div class="left-tree scrollbar">
                <el-tree
                    class="adminTree line-tree"
                    :data="adminTree"
                    default-expand-all
                    node-key="Id"
                    show-checkbox
                    ref="adminTree"
                    highlight-current
                    :props="userProps"
                    @node-click="getNodeInfo"
                    @check="checkChange"
                    :check-on-click-node="false"
                    :expand-on-click-node="false"
                    :check-strictly="true"
                >
                </el-tree>
            </div>

            <div class="right-content scrollbar">
                <el-tree
                    class="cjrTree"
                    :data="cjrTree"
                    show-checkbox
                    :default-checked-keys="checkedArr"
                    :default-expand-all="true"
                    node-key="Id"
                    ref="cjrTree"
                    highlight-current
                    :props="defaultProps"
                >
                </el-tree>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "survey",
    data() {
        return {
            adminTree: [],
            cjrTree: [],
            checkedArr: [],
            userProps: {
                label: "Name",
                children: "Children",
            },
            defaultProps: {
                label: "Name",
                children: "Children",
            },
            btnDisabled: false,
        };
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        ...mapActions([
            "GetUserByDepList",
            "GetUserAuthority",
            "SaveUserAuthority",
        ]),
        async getTableData() {
            let res = await this.GetUserByDepList();
            this.adminTree = res;
            this.cjrTree = res;
        },
        // 点击名称
        async getNodeInfo(data) {
            if (data.Children) {
                return;
            }
            this.$refs.adminTree.setCheckedKeys([data.Id]);
            let res = await this.GetUserAuthority({
                userId: data.Id,
            });
            this.checkedArr = res;
            this.$nextTick(() => {
                this.$refs.cjrTree.setCheckedKeys(this.checkedArr);
            });
        },
        // 勾选方框
        checkChange(data) {
            if (data.Children) {
                this.$refs.adminTree.setCheckedKeys([]);
            } else {
                this.$refs.adminTree.setCheckedKeys([data.Id]);
                this.getNodeInfo(data);
            }
        },
        async saveAreaData() {
            let adminTree = this.$refs.adminTree.getCheckedKeys();
            let cjrTree = this.$refs.cjrTree.getCheckedKeys(true);
            if (!adminTree.length) {
                this.common.showMsg("请选择管理人员", "warning");
                return;
            }

            if (!cjrTree.length) {
                this.common.showMsg("请选择采集人员", "warning");
                return;
            }
            let res = await this.SaveUserAuthority({
                userId: adminTree.join(","),
                pids: cjrTree.join(","),
            });
            if (res) {
                this.common.showMsg("授权成功", "success");
                this.$refs.cjrTree.setCheckedKeys([]);
                this.$refs.adminTree.setCheckedKeys([]);
            } else {
                this.common.showMsg("授权失败", "warning");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
#empower {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 20px !important;
    padding-left: 10px;
    .left-tree {
        max-height: 716px;
        overflow-x: hidden;
        width: 35%;
        margin-right: 20px;
        border: 1px solid #ccc;
        .el-tree-node {
            position: relative;
        }
    }
    .mainBtn {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 30px;
        display: inline-block;
        min-width: 80px;
        line-height: 30px;
        text-align: center;
        padding: 0 10px;
        margin-left: 10px;
        background-color: var(--headBgColor);
        color: #fff;
        border-radius: 10px;
        cursor: pointer;
    }
    .peopleLeft {
        width: 628px;
        padding-left: 20px;
        padding-bottom: 10px;
        font-size: 14px;
    }
    .peopleRight {
        margin-left: 50px;
        font-size: 14px;
    }
    .right-content {
        width: calc(65% - 20px);
        border: 1px solid #ccc;
        max-height: 716px;
    }
}
</style>
